






































import Vue from 'vue'
import { Prop, Component, Mixins } from 'vue-property-decorator'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { fetchSingleOrder } from '#/api/order'

@Component({ components: { VLoadSpinner } })
export default class OrderDetailsPage extends Vue {
    @Prop({ type: Number }) id!: number

    orderDetails: IOrderProducer = null
    isLoading = true

    get orderTotal() {
        let total = 0
        if (this.orderDetails) this.orderDetails.bought_beats.forEach(item => (total += item.producer_income))
        return total
    }

    async _fetchSingleOrder() {
        this.orderDetails = await fetchSingleOrder(this.id)
        this.isLoading = false
    }

    mounted() {
        this._fetchSingleOrder()
    }
}
