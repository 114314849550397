import { get } from '~/services/http-requests'

const _BS_ORDERS = 'sales'

/*
 **** GET ****
 */
async function fetchSingleOrder(orderId: number): Promise<IOrderProducer> {
    const [first] = await get({ baseProperty: _BS_ORDERS, fullUrl: `${_BS_ORDERS}.json?id=${orderId}&fields=id,date,bought_beats,email` })
    return first
}

function fetchCustomerEmails(): Promise<Array<{ email: string }>> {
    return get({ baseProperty: _BS_ORDERS, fullUrl: `${_BS_ORDERS}.json?fields=email` })
}

export { fetchSingleOrder, fetchCustomerEmails }
